import React from "react";
import Card from "../Card/Card";
import styles from "./CardRows.module.css";

const CardRows = ({ cardsData }) => (
  <div className={styles["card-rows"]}>
    {cardsData.map(card => (
      <Card key={card.alt} {...card} />
    ))}
  </div>
);

export default CardRows;