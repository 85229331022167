import React from 'react';
import classNames from "classnames/bind";
import withBackground from '../../hoc/withBackground/withBackground';
import withPassword from '../../hoc/withPassword/withPassword';
import styles from './Pages.module.css';

let cx = classNames.bind(styles);

const PagesContent = ({ textLeft, textRight, images, page, color }) => (
  <div className={cx([styles["page"], ["dog", "brown", "black"].includes(color) && styles["page--white-text"]])}>
    <div className={styles["page__texts"]}>
      <p className={styles["page__text-left"]}>{textLeft}</p>
      <p className={styles["page__text-right"]}>{textRight}</p>
    </div>
    <div className={styles["page__images"]}>
      {images.map((image, index) => (
        <div className={styles["page__image-wrapper"]} key={index}>
          <img
            className={styles["page__image" ]}
            src={image.src}
            alt={`${page} #${index + 1}`}
            style={{ maxWidth: image.maxWidth || 940 }}
          />
        </div>
      ))}
    </div>
  </div>
);

const Pages = withPassword(withBackground(PagesContent));

export default Pages;