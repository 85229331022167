import React from "react";
import { Link } from 'react-router-dom';
import styles from "./Card.module.css";

const Card = ({ src, alt, to, leftText, rightText }) => {
  return (
    <Link to={to} className={styles["card"]}>
      <div className={styles["text-container"]}>
        <p className={styles["text"]}>{leftText}</p>
        <p className={styles["text"]}>{rightText}</p>
      </div>
      <img src={src} alt={alt} />
    </Link>
  );
};

export default Card;