
import React from 'react'; 
import useBackground from "../../hooks/useBackground";

const withBackground = WrappedComponent => {
  return ({ setMainBackground, ...props }) => {
    useBackground({ setMainBackground, color: props.color });
    return <WrappedComponent {...props} />;
  };
};

export default withBackground;