import React, { Component } from 'react';
import { Route, Redirect, BrowserRouter, Switch } from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import NotWork from './containers/NotWork/NotWork';
import Work from './containers/Work/Work';
import Pages from './containers/Pages/Pages';
import data from './containers/Pages/data';

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Layout>
          {(setMainBackground)  => (
            <Switch>
              <Route path="/" exact render={() => <Home setMainBackground={setMainBackground} color='yellow' />} />
              <Route path="/notwork" render={() => <NotWork setMainBackground={setMainBackground} color='dog' />} />
              <Route path="/work" render={() => <Work setMainBackground={setMainBackground} color='yellow' />} />
              <Route path="/weight-tracker" render={() => <Pages setMainBackground={setMainBackground} {...data["weight"]} />} />
              <Route path="/medication-reminder" render={() => <Pages setMainBackground={setMainBackground} {...data["medication"]} />} />
              <Route path="/subscription-management" render={() => <Pages setMainBackground={setMainBackground} {...data["subscription"]} />} />
              <Route path="/apple" render={() => <Pages setMainBackground={setMainBackground} {...data["appleTV"]} />} />
              <Route path="/nike" render={() => <Pages setMainBackground={setMainBackground} {...data["nikeFleece"]} />} />
              <Route path="/the-daily" render={() => <Pages setMainBackground={setMainBackground} {...data["daily"]} />} />
              <Redirect to="/" />
            </Switch>
          )}
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;