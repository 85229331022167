import React, { useState } from 'react';

import styles from './Password.module.css';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right-icon.svg';
import { ReactComponent as HappyFace } from '../../assets/icons/happy-face-icon.svg';
import { ReactComponent as Lock } from '../../assets/icons/lock-icon.svg';
import { ReactComponent as Unlock } from '../../assets/icons/unlock-icon.svg';


const Password = ({ onCorrect }) => {
  const [showError, setShowError] = useState(false);
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [passed, setPassed] = useState(false);

  const onChangeHandler = e => {
    setValue(e.target.value);
    setDisabled(e.target.value === "");
    setShowError(false);
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    if (value === "LILKIM4EVER") {
      setPassed(true);
      onCorrect();
    } else {
      setValue("");
      setDisabled(true);
      setShowError(true);
    }
  };

  const HeaderIcon = passed ? <Unlock /> : <Lock />;
  const PasswordButtonIcon = passed ? <HappyFace /> : <ArrowRight style={{ opacity: disabled ? 0.3 : 1 }} />;

  return (
    <div className={[styles["password"], disabled && styles["password--disabled"]].join(" ")}>
      <h3 className={styles["password__header"]}>{HeaderIcon}</h3>
      <form className={styles["password__form"]} onSubmit={onSubmitHandler}>
        <input className={styles["password__input"]} type="text" value={value} onChange={onChangeHandler} />
        <button className={styles["password__button"]} type="submit" disabled={disabled}>{PasswordButtonIcon}</button>
        {showError && <p className={styles["password__error"]}>nope</p>}
      </form>
    </div>
  );
};

export default Password;