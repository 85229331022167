import React, { useState, useCallback, useEffect, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import Card from '../Card/Card';
import styles from './CardCarousel.module.css';

const PrevButton = ({ enabled, onClick }) => (
  <button className={[styles["embla__button"], styles["embla__button--prev"]].join(" ")} onClick={onClick} disabled={!enabled} />
);

const NextButton = ({ enabled, onClick }) => (
  <button className={[styles["embla__button"], styles["embla__button--next"]].join(" ")} onClick={onClick} disabled={!enabled} />
);

const CardCarousel = ({ cardsData }) => {
  const [carouselRef, emblaApi] = useEmblaCarousel({
    align: "start",
    dragFree: true,
  }, [
    WheelGesturesPlugin({
      forceWheelAxis: 'y',
    }),
  ]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  const onScroll = useCallback(() => {
    const {
      limit,
      target,
      location,
      offsetLocation,
      scrollTo,
      translate,
      scrollBody
    } = emblaApi.internalEngine()

    let edge = null

    if (location.get() > limit.max) edge = limit.max;
    if (location.get() < limit.min) edge = limit.min;

    if (edge !== null) {
      offsetLocation.set(edge)
      location.set(edge)
      target.set(edge)
      translate.to(edge)
      translate.toggleActive(false)
      scrollBody.useDuration(0).useFriction(0)
      scrollTo.distance(0, false)
    } else {
      translate.toggleActive(true)
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('scroll', onScroll)

      const onSelect = () => {
        setPrevBtnEnabled(emblaApi.canScrollPrev());
        setNextBtnEnabled(emblaApi.canScrollNext());
      }

      emblaApi.on('select', onSelect);
      onSelect();

      return () => {
        emblaApi.off('select', onSelect);
        emblaApi.off('scroll', onScroll);
      }
    }
  }, [emblaApi])

  return (
    <div className={styles["carousel"]} ref={carouselRef}>
      <div className={styles["carousel__container"]}>
        {cardsData.map(card => (
          <div key={card.alt} className={styles["carousel__slide"]}>
            <Card src={card.src} {...card} />
          </div>
        ))}
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default CardCarousel;