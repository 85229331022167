import React, { useState, useEffect } from 'react';
import Password from '../../components/Password/Password';

const withPassword = WrappedComponent => {
  return ({ password, ...props }) => {
    const [passed, setPassed] = useState(false);
    const { setMainBackground: setBackground, color } = props;

    useEffect(() => {
      setBackground(passed || !password ? color : 'yellow');
      window.scrollTo(0, 0);
    }, [password, passed, setBackground, color]);

    if (!passed && password) return <Password onCorrect={() => { setPassed(true); }} />;
    return <WrappedComponent {...props} />;
  }
}

export default withPassword;